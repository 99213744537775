import React, { useMemo } from "react"
// import utilities
import { FormatCurrency, getStatusBadge } from "helpers/Formatters/Format"
import TableContainer from "Components/Common/TableContainer"
import { formatDate } from "helpers/Formatters/DateFormatter"
import { STATUS_ZO_COMPLETED, STATUS_ZO_PENDING, STATUS_ZO_POSTED, STATUS_ZO_RETURNED, STATUS_ZO_SETTLED_FOR_BANK, STATUS_ZO_SETTLED_FOR_TFS, STATUS_ZO_SETTLED_FOR_TRUSTEE, US_DOLLAR_SYMBOL } from "slices/constants/constants"

export const TransactionList = ({
  transactionList,
  pageLimit,
  sort,
  search,
  setSort,
  setSearch,
  currentPageNumber,
  setCurrentPageNumber,
  loading,
  navigateData
}: any) => {

  const handleSort = (fieldName: string, order: string) => {
    let sort
    switch (order) {
      case "asc":
      case "desc":
        sort = {
          fieldName: fieldName,
          value: order,
        }
        break
      default:
        sort = {}
    }
    setSort(sort)
  }

  const handleSearch = (fieldName: string, value: string) => {
      setSearch((prevSearch:any) => {
        // If value is empty, remove the field from the array
        if (value === "") {
          return prevSearch.filter((item: any) => item?.fieldName !== fieldName);
        }
  
        // If the field already exists, update its value
        const existingFieldIndex = prevSearch.findIndex(
          (item: any) => item.fieldName === fieldName
        );

  
        if (existingFieldIndex !== -1) {
          // Update the existing field's value
          const updatedSearch = [...prevSearch];
          updatedSearch[existingFieldIndex].value = value;
          return updatedSearch;
        }
  
        // If the field doesn't exist, add a new field to the search array
        return [
          ...prevSearch,
          { fieldName, value }
        ];
      })
    return 
  }

  // Table headings
  const columns = useMemo(
    () => [
      {
        header: "Transaction ID",
        accessorKey: "transaction_id",
        enableColumnFilter: false, // Search Disabled
        enableSorting: false, // Sorting Disabled
        disableSort: true,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableDropDown: true, // Dropdown Enabled
        enableColumnFilter: false,
        dropDownValues: [STATUS_ZO_PENDING, STATUS_ZO_POSTED,STATUS_ZO_RETURNED, STATUS_ZO_SETTLED_FOR_TFS, STATUS_ZO_SETTLED_FOR_TRUSTEE, STATUS_ZO_SETTLED_FOR_BANK, STATUS_ZO_COMPLETED],
        enableSorting: true, // Sorting Disabled
        cell: (cell: any) => {
          const status = cell.getValue().toLowerCase()
          return (
            <span className={getStatusBadge(status)}>{cell.getValue()}</span>
          )
        },
      },
      {
        header: "Updated Date",
        accessorKey: "updated_date",
        enableColumnFilter: true, // Search Enabled
        enableSorting: true, // Sorting Enabled
        cell: (cell: any) => {
          return <span>{formatDate(cell.getValue())}</span>
        },
      },
      {
        header: "Debtor Name",
        accessorKey: "debtor_name",
        enableColumnFilter: true, // Search Enabled
        enableSorting: true, // Sorting Enabled
      },
      {
        header: "Trustee Name",
        accessorKey: "trustee_name",
        enableColumnFilter: true, // Search Enabled
        enableSorting: true, // Sorting Enabled
      },
      {
        header: "Total Amount",
        accessorKey: "total_amount",
        enableColumnFilter: true, // Search Enabled
        enableSorting: true, // Sorting Enabled
        cell: (cell: any) => {
          return (
            <div className="w-full text-right flex justify-between text-secondary">
              <span>{US_DOLLAR_SYMBOL}</span>
              <span>{FormatCurrency(cell.getValue())}</span>
            </div>
          )
        },
      },
      {
        header: "Fee Amount",
        accessorKey: "fee_amount",
        enableColumnFilter: true, // Search Enabled
        enableSorting: true, // Sorting Enabled
        cell: (cell: any) => {
          return (
            <div className="w-full text-right flex justify-between text-secondary">
              <span>{US_DOLLAR_SYMBOL}</span>
              <span> {FormatCurrency(cell.getValue())}</span>
            </div>
          )
        },
      },
    ],
    []
  )

  // Renders the Table component
  return (
    <React.Fragment>
      <TableContainer
        columns={columns || []}
        data={transactionList || []}
        customPageSize={10}
        SearchPlaceholder="Search..."
        showPagination={true}
        sort={sort}
        search={search}
        clickable={true}
        navigateTo="/timeline"
        navigateData={navigateData}
        pageLimit={pageLimit}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
        onSort={handleSort} // New prop
        onSearch={handleSearch} // New prop
        loading={loading}
      />
    </React.Fragment>
  )
}
