import React from "react"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import { TransactionList } from "./Listing"
import { FormatName } from "helpers/Formatters/Format"
import { TableContainer } from "./TableContainer"
// import TableContainer from "Components/Common/TableContainerReactTable"

export const TransactionDetails = ({ transDetails, loadingData,loadingTable, pageLimit, listDetails,  type, sort, setSort, search, setSearch, currentPageNumber,setCurrentPageNumber, navigateData }: any) => {

  return (
    <React.Fragment>
      <Row>
        <Col>
          {/* <TableContainer /> */}
          <Card className="border-1 border-gray-200">
            <CardBody className="p-0">
              <TableContainer
                summary={transDetails?.inbound_day_summary}
                loading={loadingData}
                type={type}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="border-2 border-gray-200">
            <CardHeader>
              <h3 className="card-title mb-0 uppercase">
                Transaction Type - {FormatName(type)}
              </h3>
            </CardHeader>
            <CardBody className="p-0">
              <TransactionList
                transactionList={listDetails?.transactions}
                currentPageNumber={currentPageNumber}
                setCurrentPageNumber={setCurrentPageNumber}
                sort={sort}
                setSort={setSort}
                search={search}
                setSearch={setSearch}
                pageLimit={pageLimit}
                loading={loadingTable}
                navigateData={navigateData}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
