import BreadCrumb from "Components/Common/BreadCrumb";
import Button from "Components/Common/Button";
import { formatDate } from "helpers/Formatters/DateFormatter";
import { FormatCurrency, FormatName } from "helpers/Formatters/Format";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import {
  API_SUCCESS_RESPONSE,
  US_DOLLAR_SYMBOL,
  ZERO_OUT_TIMELINE,
} from "slices/constants/constants";
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor";

const TimelineReport = () => {
  const [timelineData, setTimelineData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [transactionID, setTransactionID] = useState();
  const state = location?.state;
  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      setTransactionID(state?.key?.id?.transaction_id);
    }
  }, []);

  useEffect(() => {
    if (transactionID) {
      const getSummary = async () => {
        setIsLoading(true);

        await determineBaseUrlAndPerformApiCall(
          `${ZERO_OUT_TIMELINE}/${transactionID}`,
          "GET"
        ).then((response: any) => {
          if (response?.message !== API_SUCCESS_RESPONSE) {
            setIsLoading(false);
            toast.error(response?.message, { position: "top-center" });
          } else {
            setTimelineData(response?.data);
            setIsLoading(false);
          }
        });
      };

      getSummary();
    }
  }, [transactionID]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="page-content">
          <ToastContainer />
          <Container fluid>
            <BreadCrumb
              title={`${FormatName(state?.key?.id?.type)} Timeline`}
              pageTitle="Report"
              action="/inbound-day-summary"
              subTitle="Inbound Day Summary"
              subAction="/inbound-day-summary"
              subTitle2="Zero Out Summary Report"
              subAction2="/zero-out-summary-report"
            />
            <Row>
              <Col lg={12}>
                <Button
                  onClick={() => {
                    navigate("/inbound-day-summary", {
                      state: state?.key?.navigateData,
                    });
                  }}
                  label="Back"
                  styleType="primary"
                />
                <div className="w-full flex justify-center flex-col items-center gap-2">
                  <p className="font-bold text-primary m-0 p-0">Total Amount</p>
                  <div className="bg-white rounded-md px-28 py-2 flex justify-center items-center w-fit">
                    <p className="font-bold text-blue-500 p-0 m-0">
                      {US_DOLLAR_SYMBOL}{" "}
                      {FormatCurrency(timelineData?.total_amount)}
                    </p>
                  </div>
                  <div className="flex gap-2 flex-col md:flex-row">
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2">
                        <div className="flex justify-between items-center">
                          <p className="p-0 m-0 font-bold text-right">
                            Debtor Name
                          </p>
                          <span>:</span>
                        </div>
                        <p className="m-0 p-0">
                          {timelineData?.debtor_info?.name}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="flex justify-between items-center">
                          <p className="p-0 m-0 font-bold text-right">
                            Inbound Bundle ID
                          </p>
                          <span>:</span>
                        </div>
                        <p className="m-0 p-0">
                          {timelineData?.inbound_bundle_id}
                        </p>
                      </div>
                    </div>
                    <div className="h-10 w-[0.9px] md:bg-black md:flex hidden"/>
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2">
                        <div className="flex justify-between items-center">
                          <p className="p-0 m-0 font-bold text-right">
                            Trustee Name
                          </p>
                          <span>:</span>
                        </div>
                        <p className="m-0 p-0">
                          {timelineData?.trustee_info?.name}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="flex justify-between items-center">
                          <p className="p-0 m-0 font-bold text-right">
                            Outbound Bundle ID
                          </p>
                          <span>:</span>
                        </div>
                        <p className="m-0 p-0">
                          {timelineData?.outbound_bundle_id}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <section className="ps-timeline-sec">
                  <div className="container">
                    <ol className="ps-timeline">
                      {timelineData?.statuses?.map((item: any, index: any) => (
                        <li key={index}>
                          <div
                            className={`${
                              index % 2 === 0
                                ? "img-handler-top"
                                : "img-handler-bot"
                            }`}
                          >
                            <div className="flex items-center flex-col">
                              <div className="flex flex-col bg-white items-center rounded-md px-2 pb-2 pt-1 min-w-44 lg:min-w-52  mr-6">
                                <p className="m-0 p-0 font-bold">
                                  {formatDate(item?.date)}
                                </p>
                                <p className="m-0 p-0 capitalize border-2 rounded-md border-primary flex w-fit justify-center font-bold">
                                  {item?.status}
                                </p>
                              </div>
                            </div>
                          </div>
                          <span
                            className={`${
                              index % 2 === 0 ? "ps-sp-top" : "ps-sp-bot"
                            }`}
                          />
                        </li>
                      ))}
                    </ol>
                  </div>
                </section>
                <section className="flex w-full md:justify-end md:items-end justify-center">
                  <div className="flex bg-white rounded-md gap-4 flex-col px-10 py-4">
                    <a
                      className="text-blue-600 hover:font-semibold underline"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        timelineData?.transaction_link
                          ? timelineData?.transaction_link
                          : "/#"
                      }
                    >
                      Transaction Details
                    </a>
                  </div>
                </section>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default TimelineReport;
