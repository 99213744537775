import { FormatCurrency } from "helpers/Formatters/Format";
import React, { Fragment } from "react";
import { Table } from "reactstrap";
import { INBOUND_ACH, US_DOLLAR_SYMBOL } from "slices/constants/constants";

export const TableContainer = ({ summary, loading, type }: any) => {
  return (
    <Fragment>
      <div className="table-responsive rounded-md text-sm">
        <div>
          <Table className={"table-borders table-nowrap align-middle mb-0"}>
            <thead>
              <tr>
                <th colSpan={6}>
                  <p className="p-0 m-0 uppercase text-gray-700">
                    Inbound Transaction ID: {summary?.inbound_transaction_id}
                  </p>
                </th>
              </tr>
              <tr className="text-center bg-[#F4F3F2]">
                <th className="min-w-20"></th>
                <th colSpan={2} className="border-gray-400">
                  Trustee Portion
                </th>
                <th colSpan={2}>Fee Portion</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan={6}>
                    <div className="flex justify-center items-center h-40 w-full">
                      <div className="loader" />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td className="font-bold">Original Inbound</td>
                  <td colSpan={2} className="text-right">
                    <p className="mt-1 d-flex justify-content-between p-0 m-0">
                      <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                      <span>
                        {summary?.original_inbound?.trustee_portion
                          ? FormatCurrency(
                              summary?.original_inbound?.trustee_portion
                            )
                          : summary?.original_inbound?.trustee_portion}
                      </span>
                    </p>
                  </td>
                  <td colSpan={2} className="text-right">
                    <p className="mt-1 d-flex justify-content-between p-0 m-0">
                      <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                      <span>
                        {summary?.original_inbound?.fee_portion
                          ? FormatCurrency(
                              summary?.original_inbound?.fee_portion
                            )
                          : summary?.original_inbound?.fee_portion}
                      </span>
                    </p>
                  </td>
                  <td className="text-right text-secondary font-bold">
                    <p className="mt-1 d-flex justify-content-between p-0 m-0">
                      <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                      <span>
                        {summary?.original_inbound?.total_amount
                          ? FormatCurrency(
                              summary?.original_inbound?.total_amount
                            )
                          : summary?.original_inbound?.total_amount}
                      </span>
                    </p>
                  </td>
                </tr>
                {type === INBOUND_ACH && (
                  <>
                    <tr>
                      <td className="font-bold">Returns</td>
                      <td colSpan={2} className="text-right">
                        <p className="mt-1 d-flex justify-content-between p-0 m-0">
                          <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                          <span>
                            {summary?.returns?.trustee_portion
                              ? FormatCurrency(
                                  summary?.returns?.trustee_portion
                                )
                              : summary?.returns?.trustee_portion}
                          </span>
                        </p>
                      </td>
                      <td colSpan={2} className="text-right">
                        <p className="mt-1 d-flex justify-content-between p-0 m-0">
                          <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                          <span>
                            {summary?.returns?.fee_portion
                              ? FormatCurrency(summary?.returns?.fee_portion)
                              : summary?.returns?.fee_portion}
                          </span>
                        </p>
                      </td>
                      <td className="text-right text-secondary font-bold">
                        <p className="mt-1 d-flex justify-content-between p-0 m-0">
                          <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                          <span>
                            {summary?.returns?.total_amount
                              ? FormatCurrency(summary?.returns?.total_amount)
                              : summary?.returns?.total_amount}
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="font-bold">Untimely Returns</td>
                      <td colSpan={2} className="text-right">
                        <p className="mt-1 d-flex justify-content-between p-0 m-0">
                          <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                          <span>
                            {summary?.untimely_returns?.trustee_portion
                              ? FormatCurrency(
                                  summary?.untimely_returns?.trustee_portion
                                )
                              : summary?.untimely_returns?.trustee_portion}
                          </span>
                        </p>
                      </td>
                      <td colSpan={2} className="text-right">
                        <p className="mt-1 d-flex justify-content-between p-0 m-0">
                          <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                          <span>
                            {summary?.untimely_returns?.fee_portion
                              ? FormatCurrency(
                                  summary?.untimely_returns?.fee_portion
                                )
                              : summary?.untimely_returns?.fee_portion}
                          </span>
                        </p>
                      </td>
                      <td className="text-right text-secondary font-bold">
                        <p className="mt-1 d-flex justify-content-between p-0 m-0">
                          <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                          <span>
                            {summary?.untimely_returns?.total_amount
                              ? FormatCurrency(
                                  summary?.untimely_returns?.total_amount
                                )
                              : summary?.untimely_returns?.total_amount}
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="font-bold">Inbound After Returns</td>
                      <td colSpan={2} className="text-right">
                        <p className="mt-1 d-flex justify-content-between p-0 m-0">
                          <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                          <span>
                            {summary?.inbound_after_returns?.trustee_portion
                              ? FormatCurrency(
                                  summary?.inbound_after_returns
                                    ?.trustee_portion
                                )
                              : summary?.inbound_after_returns?.trustee_portion}
                          </span>
                        </p>
                      </td>
                      <td colSpan={2} className="text-right">
                        <p className="mt-1 d-flex justify-content-between p-0 m-0">
                          <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                          <span>
                            {summary?.inbound_after_returns?.fee_portion
                              ? FormatCurrency(
                                  summary?.inbound_after_returns?.fee_portion
                                )
                              : summary?.inbound_after_returns?.fee_portion}
                          </span>
                        </p>
                      </td>
                      <td className="text-right text-secondary font-bold">
                        <p className="mt-1 d-flex justify-content-between p-0 m-0">
                          <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                          <span>
                            {summary?.inbound_after_returns?.total_amount
                              ? FormatCurrency(
                                  summary?.inbound_after_returns?.total_amount
                                )
                              : summary?.inbound_after_returns?.total_amount}
                          </span>
                        </p>
                      </td>
                    </tr>
                  </>
                )}
                <tr className="bg-[#F4F3F2]">
                  <td rowSpan={2} className="font-bold bg-white rounded-bl-sm">
                    Disposition
                  </td>
                  <td className="font-bold text-center">Disbursed</td>
                  <td className="font-bold text-center">To be Disbursed</td>
                  <td className="font-bold text-center">Disbursed</td>
                  <td className="font-bold text-center">To be Disbursed</td>
                  <td rowSpan={2} className="bg-white rounded-br-sm"></td>
                </tr>
                <tr className="text-right">
                  <td>
                    <p className="mt-1 d-flex justify-content-between p-0 m-0">
                      <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                      <span>
                        {summary?.disposition?.trustee_portion?.disbursed
                          ? FormatCurrency(
                              summary?.disposition?.trustee_portion?.disbursed
                            )
                          : summary?.disposition?.trustee_portion?.disbursed}
                      </span>
                    </p>
                  </td>
                  <td>
                    <p className="mt-1 d-flex justify-content-between p-0 m-0">
                      <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                      <span>
                        {summary?.disposition?.trustee_portion?.to_be_disbursed
                          ? FormatCurrency(
                              summary?.disposition?.trustee_portion
                                ?.to_be_disbursed
                            )
                          : summary?.disposition?.trustee_portion
                              ?.to_be_disbursed}
                      </span>
                    </p>
                  </td>
                  <td>
                    <p className="mt-1 d-flex justify-content-between p-0 m-0">
                      <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                      <span>
                        {summary?.disposition?.fee_portion?.disbursed
                          ? FormatCurrency(
                              summary?.disposition?.fee_portion?.disbursed
                            )
                          : summary?.disposition?.fee_portion?.disbursed}
                      </span>
                    </p>
                  </td>
                  <td>
                    <p className="mt-1 d-flex justify-content-between p-0 m-0">
                      <span className="pl-1">{US_DOLLAR_SYMBOL}</span>
                      <span>
                        {summary?.disposition?.fee_portion?.to_be_disbursed
                          ? FormatCurrency(
                              summary?.disposition?.fee_portion?.to_be_disbursed
                            )
                          : summary?.disposition?.fee_portion?.to_be_disbursed}
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>
    </Fragment>
  );
};
