import React, { useEffect, useRef } from "react"

import "./assets/scss/themes.scss"
import Route from "./Routes"
import { useDispatch, useSelector } from "react-redux"
import { updateWorkflows } from "slices/workflow/reducer"
import { updateTickets } from "slices/tickets/reducer"
import { updateActions } from "slices/Actions/reducer"
import { updateNotifications } from "slices/Notification/reducer"
import { SSE_PATH } from "slices/constants/constants"
import { loginSuccess } from "slices/auth/login/reducer"
import { toast, ToastContainer } from "react-toastify"

function App() {
  const dispatch = useDispatch();

  const { isUserLoggedIn } = useSelector((state: any) => state.Login)

  const isConnected: any = useRef()

  useEffect(() => {
    const token = sessionStorage.getItem("authToken")
    if (token) {
      dispatch(loginSuccess())
    }
  }, [])

  // Single SSE
  useEffect(() => {
    if (isUserLoggedIn) {
      // Call SSE
      const token = sessionStorage.getItem("authToken")

      if (!token) {
        return;
      }

      // Prevent multiple connections
      if (isConnected.current) {
        return
      }

      const fetchData = async () => {
        try {
          const username = sessionStorage.getItem("username") || ""

          // Start the fetch request to the SSE endpoint
          const response = await fetch(
            `${window.env.REACT_APP_BASE_URL}${SSE_PATH}${username}`,
            {
              headers: {
                "Cache-Control": "no-cache",
                Accept: "text/event-stream",
                Authorization: `Bearer ${token}`,
              },
            }
          )

        // TODO: Connection timeout error
        // Check for response status
        if (response.ok && response.status === 200) {
          isConnected.current = true
        } else if (response.status === 429) {
          toast.error("You are being rate-limited. Please try again later.", {
            position: "top-center",
          })
          console.error("Rate limit error:", response)
          return
        } else if (response.status >= 400 && response.status < 500) {
          toast.error("An error occurred while connecting to the SSE. Please reach out to your administrator for assistance.", {
            position: "top-center",
          })
          console.error("Client-side error:", response)
          return
        } else if (response.status >= 500 && response.status < 600) {
          toast.error("There is an issue with the server. Please try again later.", {
            position: "top-center",
          })
          console.error("Server-side error:", response)
          return
        }

          // Get the reader for the response body
          const reader = response.body?.getReader()
          if (!reader) {
            return
          }

          const decoder = new TextDecoder()

          while (true) {
            const { value, done } = await reader.read()
            if (done) {
              break
            }

            // Decode the chunk of data received from the stream
            const chunk = decoder.decode(value, { stream: true })

            // Split the chunk by lines to handle individual messages
            const events = chunk.split("\n").filter(Boolean)
            for (const event of events) {
              if (event.startsWith("data: ")) {
                const message = event.substring("data: ".length).trim()

                try {
                  // Check if the message is using single quotes and convert to double quotes if needed
                  // const validJsonMessage = message.replace(/'/g, '"')
                  const formattedMessage = message.replace(/'/g, '"') // Replace single quotes with double quotes

                  if (formattedMessage) {
                    const parsedData = JSON.parse(formattedMessage)

                    // Update in-progress workflows
                    if (parsedData?.workflow_status_updates) {
                      dispatch(
                        updateWorkflows(parsedData?.workflow_status_updates)
                      )
                    }
                    if (parsedData?.ticket_updates) {
                      dispatch(updateTickets(parsedData?.ticket_updates))
                    }
                    if (parsedData?.notification_updates) {
                      const notificationMessage = formattedMessage
                        .replace(/False/g, "false") // Replace `False` with `false`
                        .replace(/True/g, "true")

                      const parsedNotificationData =
                        JSON.parse(notificationMessage)
                      dispatch(
                        updateNotifications(
                          parsedNotificationData?.notification_updates
                        )
                      )
                    }
                    if (parsedData?.manual_task_updates) {
                      dispatch(updateActions(parsedData?.manual_task_updates))
                    }
                  }
                  // Parse the JSON data
                } catch (error) {
                  toast.error("There's an error parsing the SSE data. Please reach out to your administrator for assistance.", {
                    position: "top-center",
                  })
                  console.error("Error parsing SSE data:", error)
                }
              }
            }
          }
        } catch (error) {
          toast.error("Error connecting to SSE. Please reach out to your administrator for assistance.", {
            position: "top-center",
          })
          console.error("Error connecting to SSE:", error)
        } finally {
          // Reset connection status
          isConnected.current = false
        }
      }

      fetchData()

      // Cleanup function to reset connection status on unmount
      return () => {
        isConnected.current = false
      }
    }
  }, [isUserLoggedIn])

  return (
    <React.Fragment>
      <ToastContainer />
      <Route />
    </React.Fragment>
  )
}

export default App