/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useRef } from "react"
import { Card, Col, Container, Row } from "reactstrap"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { DateTime } from "ts-luxon"
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"
import {
  API_SUCCESS_RESPONSE,
  MAX_DATE,
  US_DOLLAR_SYMBOL,
  ZERO_OUT_HOLIDAYS,
  ZERO_OUT_SUMMARY_REPORT,
} from "slices/constants/constants"
import { formatZOSummartReportForDisplay } from "helpers/Formatters/Format"
import { ZOSummaryListing } from "./Listing"
import { toast, ToastContainer } from "react-toastify"
import { formatDate } from "helpers/Formatters/DateFormatter"
import { useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { addDates } from "slices/Reports/InboundSummary/reducer"

const ZeroOutSummaryReport = () => {
  document.title = "Zero Out Summary | TFS Processing App"

  const location = useLocation(); // Access location object

  const [zOSummary, setZOSummary] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [disabledDates, setDisabledDates] = useState<any>(null)
  const datePickerRef = useRef<any>(null)
  const todayFormatted = DateTime.now()
    .setZone("America/New_York")
    .toFormat("MM-dd-yyyy")
  const [details, setDetails] = useState<any>({})
  const dispatch = useDispatch()

  useEffect(() => {
    // If a selectedDateProp is passed, use that value; otherwise, use today's date
    if (location?.state?.key?.outboundDate) {
      const parsedDate = location?.state?.key?.outboundDate
      setSelectedDate(parsedDate);
    } else if (MAX_DATE) { // Set Today's Date
      setSelectedDate(MAX_DATE.toJSDate());
    }
  }, [])


  const isDisabled = (date: any) => {
    // Convert to 'yyyy-mm-dd' format for comparison
    return disabledDates.some((disabledDate: any) =>
      disabledDate.toISOString().split('T')[0] === date.toISOString().split('T')[0]
    )  
  }

  const handlePreviousDate = () => {
    setSelectedDate((prevDate) => {
      const date = prevDate ? new Date(prevDate) : new Date()
      date.setDate(date.getDate() - 1)

      // Keep going back until a valid (non-disabled) date is found
      while (isDisabled(date)) {
        date.setDate(date.getDate() - 1)
      }
      return date
    })
  }

  const handleNextDate = () => {
    setSelectedDate((prevDate) => {
      const date = prevDate ? new Date(prevDate) : new Date()
      date.setDate(date.getDate() + 1)

      // Keep going forward until a valid (non-disabled) date is found
      while (isDisabled(date)) {
        date.setDate(date.getDate() + 1)
      }
      return date
    })
  }

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true)
    }
  }

  // Get the holiday list
  useEffect(() => {
    const getHolidayList = async () => {
      try {
        await determineBaseUrlAndPerformApiCall(`${ZERO_OUT_HOLIDAYS}`, "GET")
          .then((response: any) => {
            if (response?.message === API_SUCCESS_RESPONSE) {
              const dates = response?.data?.map((item: any) => new Date(item))
              setDisabledDates(dates)
            }
          })
          .catch((errorResp) => {
            toast.error(errorResp, { position: "top-center" })
            console.error(errorResp)
          })
      } catch (error: any) {
        toast.error(error?.message, { position: "top-center" })
        console.error(error)
      }
    }

    getHolidayList()
  }, [])

  useEffect(() => {
    if (selectedDate) {
      setLoading(true)
      const year = selectedDate.getFullYear()
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0") // Months are zero-indexed
      const day = selectedDate.getDate().toString().padStart(2, "0")
      const formattedSelectedDate = `${year}-${month}-${day}`

      const getSummary = async () => {
        try {
          await determineBaseUrlAndPerformApiCall(
            `${ZERO_OUT_SUMMARY_REPORT}${formattedSelectedDate}`,
            "GET"
          )
            .then((response: any) => {
              if (response?.message !== API_SUCCESS_RESPONSE) {
                setDetails({})
                setZOSummary([])
                setLoading(false)
                // toast.info(response?.message, { position: "top-center" })
              } else {
                // Bascially loop through transactions, and form an array
                // Set that to the summary data
                setDetails({
                  fboStartingBalance: response?.data?.fbo_starting_balance,
                  esquireFeeCollected: response?.data?.esquire_fee_collected,
                  zeroOutBalance: response?.data?.zero_out_balance,
                  activeWindow: response?.data?.active_window,
                })

                const item = response?.data?.transactions?.map(
                  (item: any, index: any) => {
                    const formmatedReportData = item?.data?.map((data: any) => {
                      return formatZOSummartReportForDisplay(data)
                    })

                    const report = {
                      inboundDate: formatDate(item?.inbound_date),
                      index: index + 1,
                      data: formmatedReportData,
                    }
                    return report
                  }
                )

                const inboundDates = response?.data?.transactions?.map((item:any) => item?.inbound_date)

                dispatch(addDates(inboundDates))
                
                setZOSummary(item)
                setLoading(false)
              }
            })
            .catch((errorResp) => {
              setDetails({})
              setZOSummary([])
              setLoading(false)
              toast.error(errorResp, { position: "top-center" })
              console.error(errorResp)
            })
        } catch (error: any) {
          setDetails({})
          setZOSummary([])
          setLoading(false)
          toast.error(error?.message, { position: "top-center" })
          console.error(error)
        }
      }

      getSummary()
    }
  }, [selectedDate])

  return (
    <React.Fragment>
      {/* Display loader if the reports are yet to be fetched from the API */}
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="page-content" id="processingContent">
          <ToastContainer />
          <Container fluid>
            {/* Page Heading */}
            <BreadCrumb
              title="Zero Out Summary Report"
              pageTitle="Report"
              action="/zero-out-summary-report"
            />

            {/* Date Picker */}
            <div className="flex flex-col items-center justify-center gap-2">
              <p className="text-gray-700 font-semibold m-0 p-0 text-sm">
                As of End of Day
              </p>
              <div className="flex items-center gap-x-4">
                {/* Left Arrow */}
                <div
                  onClick={handlePreviousDate}
                  className="rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2"
                >
                  <i className="ri-arrow-left-line text-white text-lg" />
                </div>
                {/* Date Picker */}
                <div className="relative inline-flex items-center w-auto justify-centre">
                  <DatePicker
                    className="border-1 border-black-500 rounded-md font-bold placeholder:text-black h-8 pl-4 text-sm w-36"
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="MM-dd-yyyy"
                    placeholderText={todayFormatted}
                    ref={datePickerRef}
                    excludeDates={disabledDates}
                    maxDate={MAX_DATE.toJSDate()}
                  />
                  <i
                    className="ri-calendar-2-line absolute right-5 bottom-1 text-gray-500 text-lg cursor-pointer"
                    onClick={handleIconClick}
                  />
                </div>
                {/* Right Arrow */}
                <div
                  onClick={handleNextDate}
                  className="rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2"
                >
                  <i className="ri-arrow-right-line text-white text-lg" />
                </div>
              </div>
            </div>

            {/* Summary Report */}
            <Row>
              <Col>
                <Card className="mt-4">
                  <ZOSummaryListing
                    selectedDate={selectedDate}
                    summaryData={zOSummary}
                    fboStartingBalance={details?.fboStartingBalance}
                    activeWindows={details?.activeWindow}
                  />
                </Card>
              </Col>
            </Row>

            <div className="flex flex-col mb-4 gap-2">
              <div className="flex justify-end w-full">
                <div className="bg-white rounded flex gap-2 justify-end pl-4 pr-2 py-2 border border-gray-300">
                  <p className="font-bold m-0 p-0 text-sm">
                    Esquire Fees Collected But Not Sent :
                  </p>
                  <p className="text-blue-500 font-bold m-0 p-0 text-sm">
                    {US_DOLLAR_SYMBOL} {details?.esquireFeeCollected}
                  </p>
                </div>
              </div>
              <div className="flex justify-end w-full">
                <div className="bg-white rounded flex gap-2 justify-end pl-4 pr-2 py-2 border border-gray-300">
                  <p className="font-bold m-0 p-0 text-sm"> Zero Out Balance :</p>
                  <p className="text-blue-500 font-bold m-0 p-0 text-sm">
                    {US_DOLLAR_SYMBOL} {details?.zeroOutBalance}
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default ZeroOutSummaryReport
