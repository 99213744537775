import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
//import logo
import logo from "../assets/images/tfs-icon.png"

//Import Components
import VerticalLayout from "./VerticalLayouts"
import { Container } from "reactstrap"
import { formatDay, formatDate } from "helpers/Formatters/DateFormatter"
import { DateTime } from "ts-luxon"

const Sidebar = ({ layoutType }: any) => {
  useEffect(() => {
    const verticalOverlay = document.getElementsByClassName("vertical-overlay")
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function() {
        document.body.classList.remove("vertical-sidebar-enable")
      })
    }
  }, [])

  // Update time every second (1000 ms)
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update current time every second
      setCurrentTime(DateTime.local().setZone("America/New_York"))
    }, 1000) // 1000 ms = 1 second

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(intervalId)
  }, [])

  const [currentTime, setCurrentTime] = useState(
    DateTime.local().setZone("America/New_York")
  )
  const formattedDate = formatDate(currentTime.toJSDate()) // Pass JavaScript Date to your formatDate function
  const formattedTime = currentTime.toLocaleString(DateTime.TIME_WITH_SECONDS) // AM/PM formatted time
  const formattedDay = formatDay(currentTime.toJSDate())

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div className="h-12 mx-4 my-3 flex justify-center">
          <Link to="/">
            <img src={logo} className="h-12 md:h-6 lg:h-12" alt="tfs-logo" />
          </Link>
        </div>
        <React.Fragment>
          <div
            id="scrollbar"
            className="flex h-5/6 sm:h-5/6 md:h-full lg:h-full flex-col justify-between"
          >
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <VerticalLayout layoutType={layoutType} />
              </ul>
            </Container>
            <Container fluid>
              <hr className="mx-3 text-white" />
              <div className="navbar-nav">
                <div className="nav-link menu-link">
                  <i className="rmx h-5 text-white bg-red text-xl ri-calendar-2-line"></i>
                  <span className="text-white pt-1.5">{formattedDay}</span>
                </div>
                <div className="nav-link menu-link">
                  <i className="rmx h-5 text-white bg-red text-xl ri-calendar-event-line"></i>
                  <span className="flex gap-0 text-white flex-col">
                    <span className="leading-none mb-1">{formattedDate},</span>
                    <span>{formattedTime}</span>
                  </span>
                </div>
              </div>
            </Container>
          </div>
          <div className="sidebar-background"></div>
        </React.Fragment>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  )
}

export default Sidebar
