import React, { useMemo } from 'react';
import TableContainer from './TableContainer';

export const ZOSummaryListing = ({ selectedDate, summaryData, fboStartingBalance, activeWindows }: any) => {
    
    const columns = useMemo(
        () => [
            {
                header: "Inbound Day",
                accessorKey: "inboundDay",
                enableColumnFilter: false,
            },
            {
                header: "Product Type",
                accessorKey: "data.productType",
                enableColumnFilter: false,
            },
            {
                header: "Trustee Portion",
                accessorKey: "data.trusteePortion",
                enableColumnFilter: false
            },
            {
                header: "Fee Portion",
                accessorKey: "data.feePortion",
                enableColumnFilter: false
            },
            {
                header: "Running Zero Out Balance",
                accessorKey: "data.runningZeroOutBal",
                enableColumnFilter: false
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <TableContainer
                selectedDate={selectedDate}
                columns={columns}
                data={summaryData || []}
                customPageSize={5}
                showPagination={true}
                fboStartingBalance={fboStartingBalance}
                clickable={true}
                activeWindows={activeWindows}
                navigateTo='/inbound-day-summary'
            />
        </React.Fragment>
    );
};
