import React, { useEffect, useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifications } from 'slices/Notification/thunk';
import { DateTime } from 'ts-luxon';
import { determineBaseUrlAndPerformApiCall } from 'slices/interceptor/interceptor';
import { GET_NOTIFICATION_UPDATEREADBY } from 'slices/constants/constants';
import { toast } from 'react-toastify';

const NotificationDropdown = () => {
    const [isNotificationDropdown, setIsNotificationDropdown] = useState<boolean>(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    const dispatch: any = useDispatch();
    useEffect(() => {
        dispatch(fetchNotifications());
    }, []);

    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const calculateTimeDifferenceFromNow = (createdAt: string) => {

        const etTime: DateTime = DateTime.fromISO(createdAt, { zone: 'America/New_York' });
        const currentTimeUTC = DateTime.now().toUTC();
        const timeDifferenceInMinutes = currentTimeUTC.diff(etTime, 'minutes').minutes;
        if (timeDifferenceInMinutes < 1) {
            return "just now";
        }

        if (timeDifferenceInMinutes < 60) {
            const minutes = Math.floor(timeDifferenceInMinutes);
            return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
        }

        const timeDifferenceInHours = currentTimeUTC.diff(etTime, 'hours').hours;
        if (timeDifferenceInHours >= 24) {
            const days = Math.floor(timeDifferenceInHours / 24);
            return days === 1 ? '1 day ago' : `${days} days ago`;
        }

        const hours = Math.floor(timeDifferenceInHours);
        const remainingMinutes = Math.floor(timeDifferenceInMinutes % 60);
        return `${hours}h ${remainingMinutes}m ago`;
    };

    const { notifications = [] } = useSelector((state: any) => state.Notifications) || {};
    // State to track selected notifications
    const [selectedNotifications, setSelectedNotifications] = useState<number[]>([]);

    const handleCheckboxChange = (index: number) => {
        setSelectedNotifications((prevSelected) =>
            prevSelected.includes(index)
                ? prevSelected.filter((i) => i !== index)
                : [...prevSelected, index]
        );
    };


    const allNotifications = notifications;
    const messageNotifications = notifications.filter((item: { type: string; }) => item.type === 'information');
    const alertNotifications = notifications.filter((item: { type: string; }) => item.type === 'alert');

    const handleStartButtonClick = async () => {

        try {
            const body = {
                "id": selectedNotifications,
                "user": sessionStorage.getItem('username')
            }

            await determineBaseUrlAndPerformApiCall(`${GET_NOTIFICATION_UPDATEREADBY}`, 'PUT', body).then((response) => {
                if (response) {
                    dispatch(fetchNotifications());
                    setSelectedNotifications([])
                }
                if (!response) {
                    toast.error("Notification not Marked as Read !", {
                        position: "top-center"
                    })
                }
            }).catch((errorResp) => {
                toast.error("Notification not Marked as Read !", {
                    position: "top-center"
                })
                console.error(errorResp)
            })

        } catch (error) {
            toast.error("Notification not Marked as Read !", {
                position: "top-center"
            })
            console.error(error)
        }
    };
    const renderNotifications = (notifications: any[]) => {
        return notifications.map((notification, index) => (
            <div key={index} className={`text-reset notification-item d-block position-relative ${notification.read ? "" : "bg-gray-100"}`}>
                <div className="d-flex">
                    <div className="avatar-xs me-3">
                        <span className={`avatar-title ${notification.type === 'alert' ? 'bg-danger-subtle text-danger' : 'bg-info-subtle text-info'} rounded-circle fs-16`}>
                            {notification.type === 'alert' ? <i className='ri-message-3-line'></i> : <i className="ri-checkbox-circle-line"></i>}
                        </span>
                    </div>
                    <div className="flex-grow-1">
                        <div className="stretched-link">
                            <span className="mt-0 mb-2 fs-13 lh-base" style={{ fontWeight: notification.read ? "normal" : "900" }}>{notification.message}</span>
                        </div>
                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                            <span><i className="ri-time-line"></i> {calculateTimeDifferenceFromNow(notification.created_at)}</span>
                        </p>
                    </div>
                    <div className="px-2 fs-15">
                        {!notification.read && (
                            <div className="form-check notification-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`notification-check-${notification.id}`}
                                    checked={selectedNotifications.includes(notification.id)}
                                    onChange={() => handleCheckboxChange(notification.id)} // Capture selection
                                />
                                <label className="form-check-label" htmlFor={`notification-check-${index}`}></label>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none">
                    <i className='ri-notification-3-line fs-17'></i>
                    <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                        {notifications.filter((notification: { read: boolean }) => !notification.read).length}
                        <span className="visually-hidden">unread messages</span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                </Col>
                            </Row>
                        </div>

                        <div className="px-2 pt-2">
                            <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}
                                    >
                                        All {allNotifications.length}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggleTab('2'); }}
                                    >
                                        Messages {messageNotifications.length}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggleTab('3'); }}
                                    >
                                        Alerts {alertNotifications.length}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                {renderNotifications(allNotifications)}
                            </SimpleBar>
                        </TabPane>
                        <TabPane tabId="2" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                {renderNotifications(messageNotifications)}
                            </SimpleBar>
                        </TabPane>
                        <TabPane tabId="3" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                {renderNotifications(alertNotifications)}
                            </SimpleBar>
                        </TabPane>
                    </TabContent>

                    {selectedNotifications.length > 0 && (
                        <div className="p-3 border-top text-center">
                            <span className="text-muted">Selected {selectedNotifications.length} Notifications</span>
                            <button onClick={() => handleStartButtonClick()} className="btn btn-link text-danger ms-3">Mark As Read</button>
                        </div>
                    )}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;