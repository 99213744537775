import React, { useMemo } from "react";
// import reusable components
import TableContainer from "Components/Common/TableContainer";
import { FormatCurrency, FormatNumber } from "helpers/Formatters/Format";
import { US_DOLLAR_SYMBOL } from "slices/constants/constants";

export const GetStatiscs = ({ statistics }: any) => {
  const columns = useMemo(
    () => [
      {
        header: "Total Transactions",
        accessorKey: "total_transactions",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="text-secondary">
              {FormatNumber(cell.getValue())}
            </span>
          );
        },
      },
      {
        header: "Total Amount",
        accessorKey: "total_amount",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
              <span className="text-secondary">
                {US_DOLLAR_SYMBOL} {FormatCurrency(cell.getValue())}
              </span>
          );
        },
      },
      {
        header: "Total Fees",
        accessorKey: "total_fees",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div className="w-full">
              <span className="text-secondary">
                {US_DOLLAR_SYMBOL} {FormatCurrency(cell.getValue())}
              </span>
            </div>
          );
        },
      },
      {
        header: "Refund Count",
        accessorKey: "refund_count",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
              <span className="text-secondary">
                {FormatNumber(cell.getValue())}
              </span>
          );
        },
      },
      {
        header: "Refund Fee Amount",
        accessorKey: "refund_fee_amount",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div className="w-full">
              <span className="text-secondary">
                {US_DOLLAR_SYMBOL} {FormatCurrency(cell.getValue())}
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <TableContainer
        columns={columns || []}
        data={statistics || []}
        customPageSize={5}
        SearchPlaceholder="Search Products..."
        showPagination={false}
        clickable={false}
      />
    </React.Fragment>
  );
};
