import React, { useMemo } from 'react'
import TableContainer from "../../Components/Common/TableContainer"
import { useNavigate } from 'react-router-dom'
import { getStatusBadge } from 'helpers/Formatters/Format'

export const ActionList = ({ actionsData }) => {
    const navigate = useNavigate()

    const columns = useMemo(
        () => [
            {
                header: "Action",
                accessorKey: "actionName",
                enableColumnFilter: false,
            },
            {
                header: "Task Name",
                accessorKey: "taskName",
                enableColumnFilter: false,
            },
            {
                header: "Workflow",
                accessorKey: "workflowName",
                enableColumnFilter: false,
                cell: (cell) => {
                    // Get the full row data using cell.row.original
                    const row = cell.row.original
                    const key = row?.key  // This is the URL you want to redirect to

                    return (
                        <span
                            className="text-blue-500 hover:underline cursor-pointer"
                            onClick={() => {
                                if (key) {
                                    navigate('/inbound-payment-processing', { state: { key: key } })
                                }
                            }}
                        >
                            {cell.getValue()} {/* Render the workflow name */}
                        </span>
                    )
                },
            },
            {
                header: "Created Date",
                accessorKey: "createdDate",
                enableColumnFilter: false,
            },
            {
                header: "Completed Date",
                accessorKey: "completedDate",
                enableColumnFilter: false
            },
            {
                header: "Status",
                enableColumnFilter: false,
                accessorKey: "status",
                cell: (cell) => {
                    const status = cell.getValue().toLowerCase()
                    return (
                        <span className={getStatusBadge(status)}>
                            {cell.getValue()}
                        </span>
                    )
                },
            },
        ],
        []
    )

    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                data={actionsData}
                customPageSize={20}
                SearchPlaceholder="Search..."
                showPagination={false}
            // clickable
            />
        </React.Fragment>
    )
}
